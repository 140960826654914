import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { Typography } from "@material-ui/core";
import { downloadFileFromLink } from "@/utils";
import Popin from "@/components/Popin";
import { MdDeleteForever } from "react-icons/md";
import { BiShow } from "react-icons/bi";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import souscriptionApi, { SouscriptionApiType } from "@/api/souscriptionApi";
import { formatDate } from "@/utils";
import { ApiTablePagine } from "@/components/ApiTablePagine";

import { typeBien } from "constants/common";
import { URLS } from "@/constants/urls";
import { FilterPayloadRecord, SortPayload } from "@/types/ApiResponse";
import PdfViewVer from "@/components/PdvViewver";
import devisApi from "@/api/devisApi";

const SouscriptionsEnCours = () => {
  const redirect = useNavigate();
  const { t } = useTranslation();
  const [reloadKey, setReloadKey] = useState(0); // État pour déclencher le rechargement
  const [urlPDF, setUrlPDF] = useState<string | null>(null);
  const [afficherPopinDevis, setAfficherPopinDevis] = useState(false);
  const [parcoursUUID, setParcoursUUID] = useState<string | null>(null);

  // Fonction pour récupérer les souscriptions
  const getSouscription = async (
    filters: FilterPayloadRecord,
    sort: SortPayload,
    currentPage: number,
    rowsPerPage: number
  ) => {
    return souscriptionApi.recupereSouscriptionsEnCoursPNO({
      page: currentPage,
      sort: sort,
      filter: filters,
      size: rowsPerPage,
    });
  };

  useEffect(() => {
    if (afficherPopinDevis && parcoursUUID) {
      generarerPdf();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [afficherPopinDevis]);
  const generarerPdf = async () => {
    try {
      const blob = await devisApi.genererDevisC2({
        parcoursUUID: parcoursUUID!,
      });
      setUrlPDF(URL.createObjectURL(new Blob([blob])));
    } catch (error) {
      setUrlPDF(null);
    }
  };
  const telechargerDevis = (nomComplet: string) => {
    if (urlPDF) {
      downloadFileFromLink(urlPDF, "Devis_PNO" + nomComplet, "pdf");
    }
  };
  const handleClickDevis = (uuid: string) => {
    setAfficherPopinDevis(true);  // Premier appel de fonction
    setParcoursUUID(uuid);         // Deuxième appel de fonction
  };

  // Fonction pour déterminer le type de bien
  const getTypeBienNom = (tybeBien?: string) => {
    switch (tybeBien) {
      case typeBien.MAISON:
        return "Maison";
      case typeBien.APPARTEMENT:
        return "Appartement";
      case typeBien.MONUMENT_HISTORIQUE:
        return "Monument Historique";
      case typeBien.GARAGE:
        return "Garage";
      case typeBien.LOCAL_COMMERCIAL:
        return "Local commercial";
      default:
        return "-";
    }
  };

  // Fonction pour gérer la suppression
  const handleSuppressionSouscription = async (uuid: string) => {
    try {
      await souscriptionApi.desactiverSouscriptionEnCoursPNO(uuid);
      setReloadKey((prevKey) => prevKey + 1); // Incrémentation de reloadKey
    } catch (error) {
      console.error("Erreur lors de la désactivation :", error);
    }
  };

  // UseEffect pour déclencher le rechargement basé sur reloadKey
  useEffect(() => {
    // On peut ajouter ici des actions supplémentaires liées au rechargement si nécessaire
    console.log("Reload triggered:", reloadKey);
  }, [reloadKey]); // Exécuté chaque fois que reloadKey change

  return (
    <ApiTablePagine<SouscriptionApiType>
      key={reloadKey} // Clé pour forcer le re-render
      rowIdentifier="id"
      getData={getSouscription}
      dataStructure={[
        {
          renderer: (e) => (
            <div

            >
              {formatDate(e.dateCreation.toString(), "DD/MM/YYYY") || "-"}
            </div>
          ),
          label: t("dashboard.pno.contract.date"),
          align: "left",
        },
        {
          renderer: (e) => (
            <div
            >
              {e.souscription.data.numFoncia || "-"}
            </div>
          ),
          label: t("dashboard.pno.contract.refPro"),
          align: "left",
        },
        {
          renderer: (e) => (
            <div
            >
              {e.souscription?.data.contact?.lastName &&
                e.souscription?.data.contact?.firstName
                ? `${e.souscription.data.contact.lastName} ${e.souscription.data.contact.firstName}`
                : "-"}
            </div>),
          label: t("pno.nom") + " " + t("pno.prenom"),
          align: "left",
        },
        {
          renderer: (e) => (
            <div
            >
              {e.souscription?.data.contractAddress?.lotNumber || "-"}
            </div>
          ),
          label: t("dashboard.pno.contract.refLotFoncia"),
          align: "left",
        },
        {
          renderer: (e) => (
            <div
            >
              {getTypeBienNom(e.souscription?.data.housingType)}
            </div>
          ),
          label: t("dashboard.pno.contract.typeBien"),
          align: "left",
        },
        {
          renderer: (e) => (
            <div
            >
              {e.souscription?.data.contractAddress?.address1 || "-"}
            </div>
          ),
          label: t("dashboard.pno.contract.adresse"),
          align: "left",
        },
        {
          renderer: (e) => (
            <div

            >
              <Trans >
                {e.souscription?.data.contractAddress?.city &&
                  e.souscription?.data.contractAddress?.zipCode
                  ? `${e.souscription.data.contractAddress.zipCode} <br /> ${e.souscription.data.contractAddress.city}`
                  : "-"}
              </Trans>
            </div>
          ),
          label: t("dashboard.pno.contract.ville"),
        },
        {
          renderer: (e) => (
            <>
              <PictureAsPdfIcon
                onClick={() => handleClickDevis(e.uuid)}
                style={{
                  color: "#FF0000",
                  textAlign: "center",
                  cursor: "pointer",
                  textDecoration: "underline",
                  marginTop: 5,
                }}
              >
                {t("pno.recapitulatif.visualiserDevis")}
              </PictureAsPdfIcon>

              <Popin
                title={t("pno.recapitulatif.visualiserDevis")}
                content={<PdfViewVer filePath={urlPDF} />}
                open={afficherPopinDevis}
                maxWidth="lg"
                onClose={() => {
                  setAfficherPopinDevis(false);
                }}
                showCancelButton={true}
                cancelButtonLabel={t("common.popin.btn_close")}
                onValidate={() => telechargerDevis(`${e.souscription.data.contact?.lastName} ${e.souscription.data.contact?.firstName}`)}
                validateButtonLabel={t("common.download")}
              />
            </>),
          label: t("dashboard.pno.contract.devis"),
        },
        {
          renderer: (e) => (
            <div style={{ padding: '10px', textAlign: 'center' }}>
              <Typography onClick={() => redirect(URLS.PNO_SOUSCRIPTION + "/" + e.uuid)}><BiShow /></Typography>
              <Typography onClick={() => handleSuppressionSouscription(e.uuid)}><MdDeleteForever /></Typography>
            </div>
          ),
        },
      ]}
    />
  );
};

export default SouscriptionsEnCours;
